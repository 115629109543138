
export function getJsonTemplate(index) {  
    var json = {
      DesignType: "Flat Roof",
      Customer: {
        JobName: "Smartkit - Via API",
  
        Address: {
            StreetNumber: '2',
            Street: "Brighton St",
            Suburb: "Croydon Park",
            State: "NSW",
            PostCode: "2133"
            },
        },
  
        Dimensions : {
            Length: 4500,
            Depth: 3000,
            Height: 2400
        },

        Roofing: {
            Roofsheet: 'FLATDEK II (No Foot Traffic)',
        },

        Posts: {
            PostType: "SHS 75mm x 2mm",
        }, 

        Beams: {
            /*'Fascia Beam': {
                BeamType: "FL10011"
            },*/
            Purlin: {
                BeamType: "FL10011"
            },
            Rafter: {
                BeamType: "FL10011"
            }
        },

        Downpipes: {
            DownpipeType: "Lysaght 100mm x 75mm Square Downpipe QLD",
        },

        Gutters: {
            GutterB: 0,
            GutterF: 1,
            GutterL: 0,
            GutterR: 0,
            GutterType: "Trimline Gutter",
        },
    
        Attachments: {
            Top: {
                AttachmentType: "Flyover",
                /*FasciaType: "Metal Fascia Drop",
                AttachmentVariation: "Steel Fascia"*/
            }, 
            Bottom: {
                AttachmentType: "Fascia with Receiver Channel",
                /*FasciaType: "Metal Fascia Drop",
                AttachmentVariation: "Steel Fascia"*/
            },
            Left: {
                AttachmentType: "Fascia with Receiver Channel",
                /*FasciaType: "Metal Fascia Drop",
                AttachmentVariation: "Steel Fascia"*/
            },
            Right: {
                AttachmentType: "Fascia with Receiver Channel",
                /*FasciaType: "Metal Fascia Drop",
                AttachmentVariation: "Steel Fascia"*/
            }
        },

        WindOptions: {
            ImportanceLevel: 2,
            Blocking: 1
        }, 

        Offerings: { value: 'SMQ', label: 'Smartkits Australia Pty Ltd', poa: 'LSB', state: 'QLD' },
    }  
    return json
}

export function getDesignType(type) {
    if(type < 4) {
        return DesingType[0]
    }
    return DesingType[1]
}

export function getRoofingSheetMap(type) {
    if(type < 4) {
        return RoofingSheetFlat
    }
    return RoofingSheetGable
}

export function getGuttersMap(type) {
    if(type < 4) {
        return GuttersFlat
    }
    return GuttersGable
}

export function getWindImportanceLevelsMap(type) {
    return [{value: 2}, { value: 3}]
}

export function getWindBlockingsMap(type) {
    return [{value: 0}, { value: 1}, {value: 2}]
}


export function getPostsMap(type) {
    if(type < 4) {
        return PostsFlat
    }
    return PostsGable
}

export function getPurlinsMap(type) {
    if(type < 4) {
        return PurlinFlat
    }
    return PurlinGable
}

export function getRaftersMap(type) {
    if(type < 4) {
        return RafterFlat
    }
    return RafterGable
}

export function getFasciasMap(type) {
    if(type < 4) {
        return FasciaFlat
    }
    return FasciaFlat
}

export function getAttachmentsMap(type) {
    if(type < 4) {
        return AttachmentFlat
    }
    return AttachmentGable
}

export function getOffering(option) {
    var index = 0
    if(option >= 0 && option < Offerings.length) index = option
    return Offerings[index]
}

export function getDownpipesMap(type) {
    if(type < 4) {
        return DownpipesFlat
    }
    return DownpipesGable
}

export const findModelTypeStringGlobal = (model, type) => {
    switch(model) {
       case 1:
           if(type) return "Flyover"
           return "insulated_flyover"
       case 2:
           if(type) return "Flyover"
           return "non_insulated_flyover"
       case 3:
           if(type) return "Patio Attached"
           return "insulated_patio_attached"
       case 4:
           if(type) return "Patio Attached" 
           return "non_insulated_patio_attached"         
       case 5:
           if(type) return "Patio Attached"
           return "insulated_patio_freestanding"         
       case 6:
           if(type) return "Patio Freestanding"
           return "non_insulated_patio_freestanding"         
       case 7:
           if(type) return "Hip"
           return "hip"   
       case 8:
           if(type) return "Hip Attached"
           return "hip_attached"
       case 9:
           if(type) return "Gable"
           return "insulated_gable"
       case 10:
           if(type) return "Gable"
           return "non_insulated_gable"
       case 11:
           if(type) return "Attached"
           return "insulated_gable_attached"         
       case 12:
           if(type) return "Gable Attached"
           return "non_insulated_gable_attached"         
       case 13:
           if(type) return "Dutch"
           return "dutch"         
       case 14:
           if(type) return "Dutch Attached"
           return "dutch_attached"   
       case 15:
           if(type) return "Gazebo 6 Sided"
           return "gazebo_6_sided"
       case 16:
           if(type) return "Gazebo 8 Sided"
           return "gazebo_8_sided"         
       case 17:
           if(type) return "Ground Level Decking"
           return "ground_level_decking"         
       case 18:
           if(type) return "High Level Decking"
           return "high_set_decking"
       case 19:
           if(type) return "Patio Carport"
           return "patio_carport"   
       case 20:
           if(type) return "Dutch Carport"
           return "dutch_carport"
       case 21:
           if(type) return "Gable Carport"
           return "gable_carport"         
       case 22:
           if(type) return "Hip Carport"
           return "hip_carport"         
       case 23:
           if(type) return "Caravan Boatport"
           return "caravan_boatport" 
       case 24:
           if(type) return "Angled Attached"
           return "angled_attached"
       case 25:
           if(type) return "Angled Freestanding"
           return "angled_freestanding"                   
       case 29:
           if(type) return "Decking Landing"
           return "decking_landing" 
       case 64:
           if(type) return "Single Garage"
           return "single_garage"         
       case 65:
           if(type) return "Double Garage"
           return "double_garage" 
       case 66:
           if(type) return "Triple Garage"
           return "triple_garage"         
       case 67:
           if(type) return "Gara Port"
           return "gara_port" 
       case 75:
           if(type) return "Skillion Garage"
           return "skillion_garage"         
       case 80:
           if(type) return "Hip Garage"
           return "hip_garage" 
       case 85:
           if(type) return "Dutch Garage"
           return "dutch_garage"                   
       }
   }

export const DesingType = ['Flat Roof', 'Gable Roof']
export const RoofingSheetFlat = [
    { value:  'FLATDEK II (No Foot Traffic)' },
    { value:  'TRIMDEK 0.42 (No Foot Traffic)' },
    { value:  'TRIMDEK 0.42 DS (No Foot Traffic)' },
    { value:  'TRIMDEK 0.42 (Non Trafficable)' },
    { value:  'TRIMDEK 0.42 D/S (Non Trafficable)' },
    { value:  'SPANDEK 0.42 (No Foot Traffic)' },
    { value:  'SPANDEK 0.42  D/S (No Foot Traffic)' },
    { value:  'SPANDEK 0.42 (Non-Trafficable)' },
    { value:  'SPANDEK 0.42 D/S (Non-Trafficable)' },
    { value:  'CUSTOM ORB - C/Bond 0.42 (No Foot Traffic)' },
    { value:  'CUSTOM ORB - C/Bond D/S 0.42 (No Foot Traffic)' },
    { value:  'CUSTOM ORB - C/Bond 0.42 (Non-Trafficable)' },
    { value:  'CUSTOM ORB - C/Bond D/S 0.42 (Non-Trafficable)' },
    { value:  'CUSTOM ORB - Zinc (Non-Trafficable)' },
    { value:  'SolarSpan 50 (Non-trafficable)' },
    { value:  'SolarSpan 75 (Non-trafficable)' },
    { value:  'SolarSpan 100 (Non-trafficable)' },
    { value:  'SolarSpan 125 (Non-trafficable)' },
    { value:  'SolarSpan 150 (Non-trafficable)' },
    { value:  'SolarSpan 200 (Non-trafficable)' },
    { value:  'SolarSpan VJ 50 (Non-trafficable)' },
    { value:  'SolarSpan VJ 75 (Non-trafficable)' },
    { value:  'SolarSpan VJ 100 (Non-trafficable)' },
    { value:  'SolarSpan VJ 125 (Non-trafficable)' },
    { value:  'SolarSpan VJ 150 (Non-trafficable)' },
    { value:  'SolarSpan VJ 200 (Non-trafficable)' },
    { value:  'Insulroof 50 (Non-trafficable)' },
    { value:  'Insulroof 75 (Non-trafficable)' },
    { value:  'Insulroof 100 (Non-trafficable)' },
    { value:  'Insulroof 125 (Non-trafficable)' },
    { value:  'Insulroof 150 (Non-trafficable)' },
    { value:  'Insulroof 200 (Non-trafficable)' },
    { value:  'Insulroof VJ 50 (Non-trafficable)' },
    { value:  'Insulroof VJ 75 (Non-trafficable)' },
    { value:  'Insulroof VJ 100 (Non-trafficable)' },
    { value:  'Insulroof VJ 125 (Non-trafficable)' },
    { value:  'Insulroof VJ 150 (Non-trafficable)' },
    { value:  'Insulroof VJ 200 (Non-trafficable)' },
    { value:  'Ampelite Solasafe Polycarbonate Custom Orb' },
    { value:  'Ampelite SolaFrost Polycarbonate Custom Orb' },
    { value:  'Ampelite HR1 Polycarbonate Custom Orb' },
    { value:  'Ampelite Solasafe Polycarbonate Greca' },
    { value:  'Ampelite HR1 Polycarbonate Greca' },
    { value:  'Ampelite Solasafe Polycarbonate 5 Rib' },
    { value:  'Apollo Trimlink 50 (Non-trafficable)' },
    { value:  'Apollo Trimlink 75 (Non-trafficable)' },
    { value:  'Apollo Trimlink 100 (Non-trafficable)' },
    { value:  'Apollo Trimlink 125 (Non-trafficable)' },
    { value:  'Apollo Trimlink 150 (Non-trafficable)' },
    { value:  'Apollo Trimlink 200 (Non-trafficable)' },
    { value:  'Apollo Corrugated 50 (Non-trafficable)' },
    { value:  'Apollo Corrugated 75 (Non-trafficable)' },
    { value:  'Apollo Corrugated 100 (Non-trafficable)' },
    { value:  'Apollo Corrugated 150 (Non-trafficable)'}
 ];
  
export const GuttersFlat =  [
      { value:  'Trimline Gutter' },
      { value:  'Trimline Gutter Slotted' },
      { value:  'FitFast Gutter' },
      { value:  'FitFast Gutter Slotted' },
      { value:  'Emline Gutter' },
      { value:  'Emline Gutter Slotted' },
      { value:  'Lysaght Quad Gutter' },
      { value:  'Lysaght Quad Gutter Slotted' },
      { value:  'Lysaght Quad 150 Gutter' },
      { value:  'Lysaght Quad 150 Hi Front Gutter' },
      { value:  'Lysaght Quad 150 Hi Front Gutter Slotted'}
    ];
      
export const DownpipesFlat = [
      { value:  '80mm Round PVC Downpipe' },
      { value:  'Lysaght 90mm Round PVC Downpipe' },
      { value:  'Lysaght 100mm x 75mm Square Downpipe QLD'}
    ];
  
export const PostsFlat = [
    { value: 'SHS 75mm x 2mm QLD' },
    { value: 'SHS 90mm x 2mm QLD' },
    { value: 'SHS 75mm x 2mm' },
    { value: 'SHS 90mm x 2mm' },
    { value: 'SHS 90mm x 3.5mm' },
    { value: 'SHS 100mm x 3mm' },
    { value: 'SHS 100mm x 4mm' },
    { value: 'SHS 125mm x 4mm' },
    { value: 'SHS 125mm x 5mm' },
    { value: 'SHS 90mm x 2mm Merbau' }
    ];

export  const PurlinFlat = [ { value: 'FL10011' }, { value: 'FL15015' }, { value: 'FL20020'} ]
export  const RafterFlat = [ { value: 'FL10011' }, { value: 'FL15015' }, { value: 'FL20020'} ]
export  const FasciaFlat = [ { value: 'FL15015' }, { value: 'FL20020' } ]

export const RoofingSheetGable = [
      { value:  'SolarSpan 75 (Non-trafficable)' },
      { value:  'FLATDEK II (No Foot Traffic)' },
      { value:  'FLATDEK II (No Foot Traffic)' },
      { value:  'TRIMDEK 0.42 (No Foot Traffic)' },
      { value:  'TRIMDEK 0.42 DS (No Foot Traffic)' },
      { value:  'TRIMDEK 0.42 (Non Trafficable)' },
      { value:  'TRIMDEK 0.42 D/S (Non Trafficable)' },
      { value:  'SPANDEK 0.42 (No Foot Traffic)' },
      { value:  'SPANDEK 0.42  D/S (No Foot Traffic)' },
      { value:  'SPANDEK 0.42 (Non-Trafficable)' },
      { value:  'SPANDEK 0.42 D/S (Non-Trafficable)' },
      { value:  'CUSTOM ORB - C/Bond 0.42 (No Foot Traffic)' },
      { value:  'CUSTOM ORB - C/Bond D/S 0.42 (No Foot Traffic)' },
      { value:  'CUSTOM ORB - C/Bond 0.42 (Non-Trafficable)' },
      { value:  'CUSTOM ORB - C/Bond D/S 0.42 (Non-Trafficable)' },
      { value:  'CUSTOM ORB - Zinc (Non-Trafficable)' },
      { value:  'SolarSpan 50 (Non-trafficable)' },
      { value:  'SolarSpan 75 (Non-trafficable)' },
      { value:  'SolarSpan 100 (Non-trafficable)' },
      { value:  'SolarSpan 125 (Non-trafficable)' },
      { value:  'SolarSpan 150 (Non-trafficable)' },
      { value:  'SolarSpan 200 (Non-trafficable)' },
      { value:  'SolarSpan VJ 50 (Non-trafficable)' },
      { value:  'SolarSpan VJ 75 (Non-trafficable)' },
      { value:  'SolarSpan VJ 100 (Non-trafficable)' },
      { value:  'SolarSpan VJ 125 (Non-trafficable)' },
      { value:  'SolarSpan VJ 150 (Non-trafficable)' },
      { value:  'SolarSpan VJ 200 (Non-trafficable)' },
      { value:  'Insulroof 50 (Non-trafficable)' },
      { value:  'Insulroof 75 (Non-trafficable)' },
      { value:  'Insulroof 100 (Non-trafficable)' },
      { value:  'Insulroof 125 (Non-trafficable)' },
      { value:  'Insulroof 150 (Non-trafficable)' },
      { value:  'Insulroof 200 (Non-trafficable)' },
      { value:  'Insulroof VJ 50 (Non-trafficable)' },
      { value:  'Insulroof VJ 75 (Non-trafficable)' },
      { value:  'Insulroof VJ 100 (Non-trafficable)' },
      { value:  'Insulroof VJ 125 (Non-trafficable)' },
      { value:  'Insulroof VJ 150 (Non-trafficable)' },
      { value:  'Insulroof VJ 200 (Non-trafficable)' },
      { value:  'Ampelite Solasafe Polycarbonate Custom Orb' },
      { value:  'Ampelite SolaFrost Polycarbonate Custom Orb' },
      { value:  'Ampelite HR1 Polycarbonate Custom Orb' },
      { value:  'Ampelite Solasafe Polycarbonate Greca' },
      { value:  'Ampelite HR1 Polycarbonate Greca' },
      { value:  'Ampelite Solasafe Polycarbonate 5 Rib' }
      ];
  
export const GuttersGable = [
      { value:  'Trimline Gutter' },
      { value:  'Trimline Gutter Slotted' },
      { value:  'FitFast Gutter' },
      { value:  'FitFast Gutter Slotted' },
      { value:  'Emline Gutter' },
      { value:  'Emline Gutter Slotted' },
      { value:  'Lysaght Quad Gutter' },
      { value:  'Lysaght Quad Gutter Slotted' },
      { value:  'Lysaght Quad 150 Gutter' }
      ];
  
export const DownpipesGable = [
      { value:  '80mm Round PVC Downpipe' },
      { value:  'Lysaght 90mm Round PVC Downpipe' },
      { value:  'Lysaght 100mm x 75mm Square Downpipe QLD' }
      ];
  
export const PostsGable = [      
    { value:  'SHS 75mm x 2mm QLD' },
    { value:  'SHS 90mm x 2mm QLD' },
    { value:  'SHS 75mm x 2mm' },
    { value:  'SHS 90mm x 2mm' },
    { value:  'SHS 90mm x 2mm Merbau' },
    { value:  'SHS 90mm x 3.5mm' },
    { value:  'SHS 100mm x 3mm' },
    { value:  'SHS 100mm x 4mm' },
    { value:  'SHS 125mm x 4mm' },
    { value:  'SHS 125mm x 5mm' }
      ];
  
export const PurlinGable = [ { value: 'FL10011' }, { value: 'FL15015' }, { value: 'FL20020' }]
  
export const RafterGable = [ { value: 'FL10011' }, { value: 'FL15015' }, { value: 'FL20020' }]
  
export const AttachmentFlat = [
    { value:  'Rafter Brackets supporting Beam' },
    { value:  'Fascia with Receiver Channel' },
    { value:  'Flyover' },
    { value:  'Wall with Receiver Channel' },
    { value:  'Wall w/Offset Brackets, Gutter & Beam' },
    { value:  'Default (Timber Fascia Drop)' },
    { value:  'Timber Fascia' },
    { value:  'Metal Fascia' },
    { value:  'Riser' },
    { value:  'Wall' }
];

export const AttachmentGable = [
    { value:  'Rafter Brackets supporting Beam' },
    { value:  'Fascia with Receiver Channel' },
    { value:  'Flyover' },
    { value:  'Wall with Receiver Channel' },
    { value:  'Wall w/Offset Brackets, Gutter & Beam' },
    { value:  'Default (Timber Fascia Drop)' },
    { value:  'Timber Fascia' },
    { value:  'Metal Fascia' },
    { value:  'Riser' },
    { value:  'Wall' }
];

export const Offerings = [
    { value: 'SMQ', label: 'Smartkits Australia Pty Ltd', poa: 'LSB', state: 'QLD' },
    {
      value: 'SMN',
      label: 'SMN-Smartkits Australia Pty Ltd',
      poa: 'EMU',
      state: 'NSW'
    },
    {
      value: 'SMV',
      label: 'SMV-Smartkits Australia Pty Ltd',
      poa: 'NUN',
      state: 'VIC'
    },
    {
      value: 'SSA',
      label: 'SSA-Smartkits Australia Pty Ltd',
      poa: 'GIL',
      state: 'SA'
    },
    {
      value: 'SMW',
      label: 'SMW-Smartkits Australia Pty Ltd',
      poa: 'KEW',
      state: 'WA'
    }
  ]
export const attachmentTypes = [
    { value: 'beam_attachment', label: 'Attached to Beam' },
    { value: 'timber_fascia_drop', label: 'Timber Fascia Drop' },
    { value: 'metal_fascia_drop', label: 'Metal Fascia Drop' },
    { value: 'riser', label: 'Riser' },
    { value: 'wall', label: 'Wall' },
    ];

export const attachmentSubTypes = [
    { value: 'timber_wall', label: 'Timber Wall' },
    { value: 'timber_wall_on_beam', label: 'Timber Wall on Beam' },
    ];

export const fasciaTypes = [
    { value: 'beam_attachment', label: 'Attached to Beam' },
    { value: 'timber_fascia_drop', label: 'Timber Fascia Drop' },
    { value: 'metal_fascia_drop', label: 'Metal Fascia Drop' },
    { value: 'riser', label: 'Riser' },
    { value: 'wall', label: 'Wall' },
    ];

export const partialAttachmentTypes = [
    { value: 'top', label: 'Top' },
    { value: 'bottom', label: 'Bottom' },
    { value: 'right', label: 'Right' },
    { value: 'left', label: 'left' },
    ];

export const bracketTypes = [
    { value: 'timber_wall', label: 'Timber Wall R/C W/ Coach Screws' },
    { value: 'timber_wall_on_beam', label: 'Timber Wall on Beam R/C on Beam W/ Coach Screws' },
    ];

export const fasciaBeamTypes = [
    { value: 'fl10011', label: 'FL10011' },
    { value: 'fl15015', label: 'FL15015' },
    { value: 'fl20020', label: 'FL20020' },
    ];

export const purlinBeamTypes = [
    { value: 'fl10011', label: 'FL10011' },
    { value: 'fl15015', label: 'FL15015' },
    { value: 'fl20020', label: 'FL20020' },
    ];

export const rafterBeamTypes = [
    { value: 'fl10011', label: 'FL10011' },
    { value: 'fl15015', label: 'FL15015' },
    { value: 'fl20020', label: 'FL20020' },
    ];

export const footingTypes = [
    { value: 'concrete_in', label: 'Concrete In' },
    { value: 'bolt_to_slab', label: 'Bolt to Slab' },
    ];

/*export const postTypes = [
    { value: 'shs_60mmx1.6mm', label: 'SHS 60mm X 1.6mm' },
    { value: 'shs_65mmx2.5mm', label: 'SHS 60mm X 2.5mm' },
    { value: 'shs_75mmx2mm', label: 'SHS 60mm X 2mm' },
    { value: 'shs_90mmx2mm', label: 'SHS 60mm X 2mm' },
    { value: 'shs_100mmx3mm', label: 'SHS 60mm X 3mm' },
    { value: 'shs_125mmx4mm', label: 'SHS 60mm X 4mm' },
    { value: 'shs_90mmx2mm_merbau', label: 'SHS 60mm X 2mm Merbau' },
    { value: 'shs_90mmx2mm_al', label: 'SHS 60mm X 2mm AL' },
    ];

export const roofingSheetTypes = [
    { value: 'flatdeck_ii_no_foot_traffic', label: 'Flatdeck II (No Foot Traffic)' },
    { value: 'flatdeck_ii_non_trafficable', label: 'Flatdeck II (Non Trafficable)' },
    ];

    export const gutterTypes = [
    { value: 'trimline_gutter', label: 'Trimline Gutter' },
    { value: 'trimline_gutter_slotted', label: 'Trimline Gutter Slotted' },
    ];*/